@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
* {
  margin: 0;
  color: #264653;
}
body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat'  ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
