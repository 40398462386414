@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
* {
  margin: 0;
  color: #264653;
}
body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat'  ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


img.header-logo-image{
    width: 80%;
  }
  .header-logo-container, nav.header-nav-container {
    width: 50%;
  }
  header.header-component{
    position: fixed;
    min-height: 5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #264653;
    background: #E9C46A;
  }
  
  ul.header-nav-menu {
    display: flex;
    justify-content: space-evenly;
  }
  
  li.header-nav-menu-item {
    list-style: none;
  }
  
  .header-logo-container {
    display: flex;
    justify-content: center;
    width: 25%;
  }
  
  img.header-logo-image {
    width: 80%;
  }
  .about-section-container, .services-section-container,  .home-section-hero{
    padding-top: 10em;
    /* min-height: 100vh; */
  }
  .home-section-hero{
    padding-top: 5em;
      /* background:linear-gradient(rgba(42, 157, 143, 0.9), rgba(42, 157, 143, 0.9)), center url(../images/hero-02.jpg); */
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 1;
      flex-direction: column;
  }
span.section-title, span.section-blurb{
  color: #264653;
  font-size: 1.5em;
  margin: 0 2em;
}
span.section-title{
  font: 3em;
  font-weight: bold;
}
  span.hero-section-header {
    font-size: 5em;
    font-weight: bold;
    color: #e9c46a;
}

input#name, input#email{
  margin-top: 2px;
  min-height: 30px;
  border: 1px solid lightgray;
}
label{
  margin-top:  10px;
}
.errors{
  color: #E76F51;
  font-weight: bold;
}
label > span{
  font-size: .5em;
    padding-left: 4px;
    font-weight: 200;
    color: #E76F51;
}
span.section-blurb.form-submission {
  margin-top: 2em;
  font-weight: 500;
}

form.signupForm {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  width: 50%;
}

input[type="submit"] {
  border: none;
  background: #2A9D8F;
  color: white;
  font-size: 1em;
  padding: 20px 0;
  margin: 30px 0;
  text-transform: uppercase;
  font-weight: bold;
}
input[type="submit"]:disabled {
opacity: .4;
}
.header-nav-menu-item > .active {
  font-weight: bold;
  text-decoration: underline;
}
.header-nav-menu-item, .header-logo-image {
/* cursor: pointer; */
}

.services-section-container{
 background: #2A9D8F;
}
#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
div.hero-section-splach-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,.95);
  padding: 20px 0px;
}
